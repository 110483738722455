import { CommonModule, CurrencyPipe, NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, input, output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { fadeAnimation } from 'src/app/animations';
import { stopPropagation } from 'src/app/helpers';
import { ProductModelResponseDto } from 'src/app/models';
import { PresentationCasePipe } from 'src/app/pipes';
import { LocalesService } from 'src/app/services';
import { AppBasicComponent } from '../app-basic/app-basic.component';
import { WaytrIcon } from '../waytr-icon/waytr-icon.component';
/* eslint-disable @angular-eslint/component-selector */

@Component({
  selector: 'venue-menu-item',
  templateUrl: './venue-menu-item.component.html',
  styleUrls: ['./venue-menu-item.component.scss'],
  animations: [fadeAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    AppBasicComponent,
    NzSkeletonModule,
    NgOptimizedImage,
    WaytrIcon,
    PresentationCasePipe,
    TranslateModule,
    CurrencyPipe,
  ],
})
export class VenueMenuItemComponent {
  readonly #localesService = inject(LocalesService);

  item = input.required<ProductModelResponseDto>();
  protected addToCartEvent = output<boolean>();

  protected animationInProgress = false;
  protected currentLocale = this.#localesService.currentLocale;

  addToCart(event: MouseEvent | TouchEvent | KeyboardEvent) {
    stopPropagation(event);
    this.addToCartEvent.emit(true);
    this.animationInProgress = true;

    setTimeout(() => {
      this.animationInProgress = false;
    }, 1000);
  }

  get scaleAndAmount(): string {
    const quantity = this.item()?.quantity;

    if (quantity) return quantity?.amount + quantity?.scale;

    return '';
  }
}
