<cdk-virtual-scroll-viewport orientation="horizontal" itemSize="70" class="no-scrollbar">
  <div
    *cdkVirtualFor="let category of categories(); let i = index"
    [@fadeAnimation]
    class="categories__card non-selectable clickable"
    tabindex="0"
    (click)="selectCategory(category.name)"
    (keyup)="selectCategory(category.name)"
    [class.toggle]="selectedCategory() === category.name">
    <span class="categories__card__icon" [title]="category.icon | titlecase">
      <waytr-icon [name]="category.icon || 'orders'" width="16px" height="16px"></waytr-icon>
    </span>
    <p class="categories__card__text">
      {{ category.name | titlecase }}
    </p>
  </div>
</cdk-virtual-scroll-viewport>
