import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, input, model } from '@angular/core';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { fadeAnimation } from 'src/app/animations';
import { debounce } from 'src/app/helpers';
import { CategoryModelResponseDto } from 'src/app/models';
import { WaytrIcon } from '../waytr-icon/waytr-icon.component';
/* eslint-disable @angular-eslint/component-selector */

@Component({
  selector: 'venue-categories',
  templateUrl: './venue-categories.component.html',
  styleUrls: ['./venue-categories.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeAnimation],
  imports: [CommonModule, ScrollingModule, NzSkeletonModule, WaytrIcon],
})
export class VenueCategoriesComponent {
  categories = input<CategoryModelResponseDto[]>([]);
  selectedCategory = model<string | undefined>();

  @debounce(100)
  selectCategory(categoryName: string) {
    if (this.selectedCategory() === categoryName) {
      this.selectedCategory.set(undefined);
    } else {
      this.selectedCategory.set(categoryName);
    }
  }
}
