import { animate, style, transition, trigger } from '@angular/animations';
import { animationInTimings, animationOutTimings } from '../constants/animations';

export const fadeScaleAnimation = trigger('fadeScaleAnimation', [
  transition(':enter', [
    style({ opacity: 0, transform: 'scale(1.05)' }),
    animate(animationInTimings, style({ opacity: 'revert-layer', transform: 'scale(1)' })),
  ]),
  transition(':leave', [
    style({ opacity: 1, transform: 'scale(1)' }),
    animate(animationOutTimings, style({ opacity: 0, transform: 'scale(1.05)' })),
  ]),
]);
