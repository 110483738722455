import { animate, style, transition, trigger } from '@angular/animations';
import { animationInSlowTimings, animationOutSlowTimings } from '../constants/animations';

export const addOrRemoveAnimation = trigger('addOrRemoveAnimation', [
  transition(':enter', [
    style({ opacity: 0, transform: 'translateY(2rem) scale(0.95)' }),
    animate(animationInSlowTimings, style({ opacity: 1, transform: 'translateY(0) scale(1)' })),
  ]),
  transition(':leave', [
    style({ opacity: 1, transform: 'translateY(0) scale(1)' }),
    animate(animationOutSlowTimings, style({ opacity: 0, transform: 'translateY(2rem) scale(0.95)' })),
  ]),
]);
