<app-basic-component [@addOrRemoveAnimation]>
  <ng-template #template>
    <div class="notifications" [class.full-width]="!hasAction">
      <div class="notifications__title">
        <span class="notifications__title__icon {{ icon() }}"></span>
        <h3>{{ message() }}</h3>
      </div>
      @if (hasAction()) {
        <div class="notifications__action">
          <button
            class="notifications__action__confirmation button-accent blur"
            tabindex="0"
            (click)="acceptClicked($event)"
            (keyup)="acceptClicked($event)">
            {{ 'accept' | translate }}
          </button>
          <h4 tabindex="0" (click)="declineClicked($event)" (keyup)="acceptClicked($event)">{{ 'reject' | translate }}</h4>
        </div>
      }
    </div>
  </ng-template>
</app-basic-component>
