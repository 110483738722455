import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, WritableSignal, input, signal } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { addOrRemoveAnimation } from 'src/app/animations';

@Component({
  selector: 'app-menu-action',
  imports: [CommonModule, TranslateModule],
  templateUrl: './app-menu-action.component.html',
  styleUrls: ['./app-menu-action.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [addOrRemoveAnimation],
})
export class AppMenuActionComponent {
  title = input('');
  icon = input('');
  details = input('');
  disabled = input(false);
  protected animation: WritableSignal<boolean> = signal(false);

  startAnimation() {
    this.animation.set(true);

    setTimeout(() => {
      this.animation.set(false);
    }, 2000);
  }
}
