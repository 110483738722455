import { CommonModule, NgOptimizedImage, isPlatformBrowser } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, OnInit, Signal, WritableSignal, computed, inject, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, NavigationEnd, Params, Router, RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { filter } from 'rxjs';
import { addOrRemoveAnimation } from './animations';
import { LOCALE_KEY, MAIN_LINKS, ROMANIAN } from './constants';
import { stopPropagation, updateLinks } from './helpers';
import { ISessionParams } from './interfaces';
import { PagesEnum } from './models';
import { AppLinksTypeModel } from './models/types';
import { FingerprintService, LocalStorageService, LocalesService, RouterStateService, TitleService } from './services';
import { SessionStateService } from './services/session-state.service';
import {
  AppMenuBarComponent,
  AppMenuIconComponent,
  WaytrIconsService,
  waytrAdmin,
  waytrAnalytics,
  waytrArrowDown,
  waytrArrowLeft,
  waytrArrowRight,
  waytrBowl,
  waytrBreakfast,
  waytrBurger,
  waytrBusy,
  waytrCard,
  waytrCart,
  waytrChicken,
  waytrClean,
  waytrClose,
  waytrCutlery,
  waytrDirectSale,
  waytrDrink,
  waytrEdit,
  waytrFoodMenu,
  waytrFresh,
  waytrHash,
  waytrLink,
  waytrLink2,
  waytrMushrooms,
  waytrNoodle,
  waytrNotification,
  waytrOrders,
  waytrPastry,
  waytrPizza,
  waytrSales,
  waytrSaltAndPepper,
  waytrSearch,
  waytrSoup,
  waytrStar,
  waytrSushi,
  waytrSweet,
  waytrTable,
  waytrTableManagement,
  waytrTaco,
  waytrTissue,
  waytrUpload,
  waytrWaiter,
} from './shared';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [addOrRemoveAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    RouterOutlet,
    RouterLink,
    RouterLinkActive,
    NgOptimizedImage,
    NzSkeletonModule,
    AppMenuBarComponent,
    AppMenuIconComponent,
    TranslateModule,
  ],
})
export class AppComponent implements OnInit {
  readonly #router = inject(Router);
  readonly #routerStateService = inject(RouterStateService);
  readonly #activatedRoute = inject(ActivatedRoute);
  readonly #sessionStateService = inject(SessionStateService);
  readonly #destroyRef = inject(DestroyRef);

  protected pages = PagesEnum;
  protected stopPropagation = stopPropagation;

  protected appMenuBarShown: Signal<boolean> = this.#routerStateService.appMenuBarShown;
  protected routingParams: Signal<ISessionParams | undefined> = this.#routerStateService.routingParams;

  protected navigationLinks: Signal<AppLinksTypeModel[]> = computed(() => this.calculateNavigationLinks(this.routingParams()));
  protected zoomed: WritableSignal<boolean> = signal(true);
  protected elementLoaded = true;

  constructor() {}

  ngOnInit() {
    this.#sessionStateService.createAppState();

    this.#router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        takeUntilDestroyed(this.#destroyRef),
      )
      .subscribe(() => {
        this.zoomed.set(true);
        const params = this.#activatedRoute.firstChild?.snapshot.params;
        if (params) {
          this.setEventRoutingParams(params);
        }
      });
  }

  private calculateNavigationLinks(routingParams: ISessionParams | undefined) {
    return routingParams ? updateLinks(MAIN_LINKS, this.routingParams()) : MAIN_LINKS;
  }

  private setEventRoutingParams(params: Params) {
    const waytrSessionParams = {
      venue: params.venue,
      table: params.table,
      sessionUrl: params.sessionUrl ?? 'menu',
    };

    this.#routerStateService.setRoutingParams(waytrSessionParams);
    this.#routerStateService.showAppMenuBar();
  }

  zoomInApp(event: MouseEvent | TouchEvent | KeyboardEvent) {
    this.stopPropagation(event);
    this.zoomed.set(true);
  }

  toggleZoom(event: MouseEvent | TouchEvent | KeyboardEvent) {
    this.stopPropagation(event);
    this.zoomed.set(!this.zoomed());
  }
}

export function appInitializerFactory(
  titleService: TitleService,
  localesService: LocalesService,
  fingerprintService: FingerprintService,
  localStorageService: LocalStorageService,
  translateService: TranslateService,
  waytrIconsService: WaytrIconsService,
  platformId: Object,
): () => Promise<void> {
  return () => {
    return new Promise<void>(async resolve => {
      waytrIconsService.addIcons([
        waytrFresh,
        waytrBowl,
        waytrCart,
        waytrChicken,
        waytrCutlery,
        waytrMushrooms,
        waytrPastry,
        waytrPizza,
        waytrSweet,
        waytrOrders,
        waytrFoodMenu,
        waytrTableManagement,
        waytrWaiter,
        waytrDirectSale,
        waytrTable,
        waytrAnalytics,
        waytrNotification,
        waytrBusy,
        waytrStar,
        waytrSales,
        waytrHash,
        waytrLink,
        waytrCard,
        waytrUpload,
        waytrEdit,
        waytrLink2,
        waytrTaco,
        waytrNoodle,
        waytrBreakfast,
        waytrSoup,
        waytrSushi,
        waytrBurger,
        waytrDrink,
        waytrAdmin,
        waytrSearch,
        waytrSaltAndPepper,
        waytrTissue,
        waytrClean,
        waytrClose,
        waytrArrowDown,
        waytrArrowLeft,
        waytrArrowRight,
      ]);

      if (isPlatformBrowser(platformId)) {
        fingerprintService.initializeFingerprint();
        const locale = (await localStorageService.getData<string>(LOCALE_KEY)) || ROMANIAN;
        translateService.setDefaultLang(locale);
        titleService.loadInitialTitle('');
        localesService.setLocale(locale);
        localesService
          .getAllLocales()
          .then(locales => {
            localesService.setAllLocales(locales);
          })
          .finally(() => {
            resolve();
          });
      } else {
        resolve();
      }
    });
  };
}
